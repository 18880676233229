import { Grid, Timeline } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';
import { Heading } from '..';

type PropsSectionProcess = {
  title: string;
  style?: any;
  steps: {
    title: string;
    description: string;
    width?: any;
  }[];
};
const { useBreakpoint } = Grid;

export const SectionProcess = ({
  title,
  steps,
  style,
}: PropsSectionProcess) => {
  const [activeStep, setActiveStep] = useState([0]);
  const { xs: isMobile } = useBreakpoint();
  const { t } = useTranslation();

  const [colors] = useState([
    '#117BB7',
    '#1172A9',
    '#0D4983',
    '#09215F',
    '#09215F',
    '#09215F',
  ]);

  const formatIndex = (number) => {
    return <>{`${t('common.txtStep')} ${number + 1}`}</>;
  };

  const handleActiveStep = (index) => () => {
    if (isMobile) {
      return;
    }

    const listIndex = steps
      .filter((_, i) => i <= index)
      .map((_, index) => index);
    setActiveStep(listIndex);
  };

  useEffect(() => {
    if (isMobile) {
      const listIndex = steps.map((_, index) => index);
      setActiveStep(listIndex);
    }
  }, [isMobile]);

  return (
    <div className="section-process">
      <Heading title={title} />
      <div className="timeline">
        <Timeline mode="left">
          {steps.map((step, index) => (
            <Timeline.Item
              key={index}
              label={formatIndex(index)}
              dot={
                <div
                  className={classNames({
                    'circle-hover': activeStep.includes(index),
                  })}
                >
                  <div
                    className="circle-outer"
                    style={{ borderColor: colors[index] }}
                  >
                    <div
                      className="circle-inner"
                      style={{ backgroundColor: colors[index] }}
                    ></div>
                  </div>
                </div>
              }
            >
              <div
                onMouseMove={handleActiveStep(index)}
                className={classNames('step-content', {
                  active: activeStep.includes(index),
                })}
              >
                {isMobile && (
                  <span className="step-number">{formatIndex(index)}</span>
                )}
                <div className="step-label" style={style}>
                  <span
                    className="step-title"
                    style={{
                      backgroundColor: colors[index],
                      width: isMobile ? 'auto' : step.width,
                    }}
                  >
                    {step.title}
                    <img src="/icons/icon-right-arrow.svg" alt="Right Arrow" />
                  </span>
                </div>
                <span
                  className="step-description"
                  style={{ borderColor: colors[index] }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: step.description,
                    }}
                  />
                </span>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
    </div>
  );
};
