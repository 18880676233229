import { Heading } from '@components/Layout/Service';
import { Col, Collapse, Row, Space } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

const { Panel } = Collapse;

type PropsSectionConcept = {
  className?: string;
  data: any;
  thumbnail?: string;
  alt?: string;
  title?: any;
  renderExpandIcon?: any;
  screen?: any;
  showReturnIcon?: boolean;
  gutter?: boolean;
};

const SectionConcept = ({
  data,
  className,
  thumbnail,
  title,
  alt,
  screen,
  renderExpandIcon,
  showReturnIcon = false,
  gutter = true,
}: PropsSectionConcept) => {
  const { t } = useTranslation();

  const renderExpandIconDefault = ({ isActive }) => {
    return isActive ? (
      <img
        src="/icons/arrow-top-black.svg"
        alt="Arrow down"
        className="icon-down-arrow"
      />
    ) : (
      <img
        src="/icons/arrow-down-black.svg"
        alt="top down"
        className="icon-top-arrow"
      />
    );
  };

  const screenDefault = {
    colLeft: {
      xs: 24,
      lg: {
        span: 12,
        order: 2,
      },
    },
    colRight: {
      xs: 24,
      lg: 12,
    },
  };

  return (
    <div className={classNames('section section-concept', className)}>
      <Heading title={title || t('service.blockchainConceptTitle')} />
      <Row gutter={[gutter && 60, gutter && 32]}>
        <Col {...(screen?.colLeft || screenDefault?.colLeft)}>
          <img
            src={
              thumbnail || '/images/services/blockchain/thumbnail-concept.webp'
            }
            alt={alt || 'blockchain development services process'}
            className="concept__thumbnail"
            style={{
              maxWidth: '100%',
            }}
          />
        </Col>
        <Col
          {...(screen?.colRight || screenDefault?.colRight)}
          className="col-info"
        >
          <Collapse
            defaultActiveKey={['0']}
            expandIconPosition="right"
            expandIcon={renderExpandIcon || renderExpandIconDefault}
            accordion
          >
            {data.map(({ title, content }, index) => (
              <Panel
                header={
                  <Space size={16}>
                    <span className="concept__step">
                      {t('common.step', {
                        number: index + 1,
                      })}
                    </span>
                    <span className="concept__dot" />
                    <span className="concept__title">{title}</span>
                  </Space>
                }
                key={index}
              >
                {showReturnIcon ? (
                  <Space size={20}>
                    <Image
                      src="/icons/icon-keyboard_return.svg"
                      alt="keyboard-return"
                      width={24}
                      height={24}
                      quality={100}
                      objectFit="cover"
                    />
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  </Space>
                ) : (
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                )}
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};

export { SectionConcept };
