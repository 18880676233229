import { Heading } from '@components/Layout/Service';
import { Tooltip } from 'antd';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { disableLinkEvent } from 'utils';

interface OurAwardInterface {
  listLogo?: any[];
  listLogoMobile?: any[];
  iconClickable: boolean;
}

const OurAward = ({
  listLogo,
  listLogoMobile,
  iconClickable,
}: OurAwardInterface) => {
  const { t } = useTranslation();

  return (
    <div className="our-awards">
      <Heading
        title={t('about-us.outAwardTitleBig')}
        titleLevel={2}
        subtitle={t('about-us.outAwardTitleSmall')}
      />

      <div className="our-awards__logo lap">
        {iconClickable
          ? listLogo.map((item, id) => (
              <div className="our-awards__item" key={id}>
                {item.map((logo, id) => (
                  <Link href={logo?.link} key={id}>
                    <a
                      target="_blank"
                      onClick={disableLinkEvent(logo?.link)}
                      rel="nofollow"
                    >
                      <Tooltip
                        placement="right"
                        title={logo.content}
                        key={id}
                        className="tooltip-logo"
                      >
                        <Image
                          src={logo.image}
                          alt={logo.alt}
                          width={200}
                          height={200}
                          objectFit="contain"
                          quality={100}
                        />
                      </Tooltip>
                    </a>
                  </Link>
                ))}
              </div>
            ))
          : listLogo.map((item, id) => (
              <div className="our-awards__item" key={id}>
                {item.map((logo, id) => (
                  <Tooltip
                    placement="right"
                    title={logo.content}
                    key={id}
                    className="tooltip-logo"
                  >
                    <Image
                      src={logo.image}
                      alt={logo.alt}
                      width={200}
                      height={200}
                      objectFit="contain"
                      quality={100}
                    />
                  </Tooltip>
                ))}
              </div>
            ))}
      </div>
      <div className="our-awards__mobile">
        {iconClickable
          ? listLogoMobile.map((logo, id) => (
              <Link href={logo?.link} key={id}>
                <a
                  target="_blank"
                  onClick={disableLinkEvent(logo?.link)}
                  rel="nofollow"
                >
                  <Image
                    key={id}
                    src={logo.image}
                    alt={logo.alt}
                    width={100}
                    height={100}
                    objectFit="contain"
                  />
                </a>
              </Link>
            ))
          : listLogoMobile.map((logo, id) => (
              <Image
                key={id}
                src={logo.image}
                alt={logo.alt}
                width={100}
                height={100}
                objectFit="contain"
              />
            ))}
      </div>
    </div>
  );
};

export default OurAward;
