import { Col, Row, Grid } from 'antd';
import { TFunction, useTranslation } from 'next-i18next';
import { Heading } from '@components/Layout/Service';
import striptags from 'striptags';
import { Item } from './Item';

interface InterfaceOurAchievementsV2 {
  title?: string;
  subTitle?: string;
  isShowTitle?: boolean;
}

const achievements = (t: TFunction) => [
  {
    label: t('home.textMarketsServed'),
    number: 10,
  },
  {
    label: t('home.txtEndToEndProjects'),
    number: 450,
  },
  {
    label: t('home.txtActiveClientAccounts'),
    number: 120,
  },
  {
    label: t('home.txtTechSpecialists'),
    number: 180,
  },
];

const { useBreakpoint } = Grid;

export const OurAchievementsV2 = ({ title, subTitle, isShowTitle = true }: InterfaceOurAchievementsV2) => {
  const { t } = useTranslation();
  const { md } = useBreakpoint();

  return (
    <div className="our-achievements-v2">
      <div className="our-achievements-v2__container">
        {isShowTitle && (
          <Heading
            title={title || t('home.txtOurProudAchivement')}
            titleLevel={2}
            subtitle={
              subTitle ||
              (md ? t('home.txtOurProudAchivementDescription') : striptags(t('home.txtOurProudAchivementDescription')))
            }
          />
        )}
        <div className="our-achievements-v2__body">
          <Row justify="space-between">
            {achievements(t).map(({ label, number }) => (
              <Col className="our-achievements-v2__body--item" key={label} md={6} xs={12}>
                <Item number={number} label={label} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};
