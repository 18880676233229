import { Col, Row } from 'antd';
import { FC } from 'react';
import { Heading } from '../Heading';

const SectionWhyEkoios: FC<{
  title: string;
  data: {
    title: string;
    description: string;
    image?: any;
  }[];
  showImage?: boolean;
}> = ({ title, data, showImage = true }) => {
  return (
    <div className="section section-why-ekoios">
      <Heading title={title} />
      <Row gutter={[24, 32]}>
        {data.map(({ title, description, image }, index) => (
          <Col key={index} md={12}>
            <div className="section-why-ekoios__card">
              <img src="/icons/icon-orange-tick.svg" alt="Right Arrow" className="icon" />
              <div>
                <div className="section-why-ekoios__title">{title}</div>
                <div className="section-why-ekoios__description">{description}</div>
                {showImage && <img src={image} className="section-why-ekoios__logo" alt="Why Ekotek" />}
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export { SectionWhyEkoios };
