import { Collapse } from 'antd';
import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import striptags from 'striptags';
import { Heading } from '..';

const { Panel } = Collapse;

type PropFAQ = {
  list: {
    question: string;
    answer: string;
  }[];
  title?: string;
};

export const SectionFAQ = ({
  list = [
    {
      question: '',
      answer: '',
    },
  ],
  title,
}: PropFAQ) => {
  const { t } = useTranslation();
  const renderExpandIcon = ({ isActive }) => {
    if (isActive) {
      return <img src="/icons/icon-minus-v3.svg" alt="icon-minus" />;
    }

    return <img src="/icons/icon-plus-v3.svg" alt="icon-plus" />;
  };

  const panelHeader = (item, index) => {
    return (
      <div className="faq__title">
        <span>0{index + 1}</span>
        <span>{item.question}</span>
      </div>
    );
  };

  return (
    <>
      <Head>
        {
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify({
                '@context': 'https://schema.org',
                '@type': 'FAQPage',
                mainEntity: list.map(({ question, answer }) => ({
                  '@type': 'Question',
                  name: question,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: striptags(answer),
                  },
                })),
              }),
            }}
          />
        }
      </Head>
      <div className="section-faq">
        <div className="section-faq__top">
          <div className="container">
            <Heading title={title || t('service.titleFAQ')} />
          </div>
        </div>
        <div className="container">
          <Collapse
            defaultActiveKey={['0']}
            className="section-faq__block"
            expandIconPosition="right"
            expandIcon={renderExpandIcon}
          >
            {list.map((item, index) => (
              <Panel header={panelHeader(item, index)} key={index}>
                <div dangerouslySetInnerHTML={{ __html: item.answer }} />
              </Panel>
            ))}
          </Collapse>
        </div>
        <div className="section-note" dangerouslySetInnerHTML={{ __html: t('web.txtEmailContact') }} />
      </div>
    </>
  );
};
