import { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';
import { IconPlusBig } from '@public/icons/PlusBig';

export const Item = ({ number, label }) => {
  const [active, setActive] = useState(true);

  const handleEndCountUp = () => setActive(false);

  return (
    <div className="achievement">
      <VisibilitySensor
        partialVisibility
        offset={{ bottom: 200 }}
        active={active}
        minTopValue={2}
      >
        {({ isVisible }) => (
          <div className="count-up">
            {isVisible && (
              <span className="number">
                <CountUp
                  end={number}
                  duration={1}
                  separator="."
                  onEnd={handleEndCountUp}
                />
                <IconPlusBig />
              </span>
            )}
          </div>
        )}
      </VisibilitySensor>

      <div className="label">{label}</div>
    </div>
  );
};
