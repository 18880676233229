import { Carousel, Col, Row, Grid } from 'antd';
import classNames from 'classnames';
import React from 'react';
import LazyLoad from 'react-lazy-load';
import { Heading } from '..';

const { useBreakpoint } = Grid;

type PropsSectionStack = {
  title: string;
  description?: string;
  stacks: any;
};

export const SectionStack = ({
  title,
  description,
  stacks = [
    {
      label: '',
      grid: 5,
      items: [],
    },
  ],
}: PropsSectionStack) => {
  const { md } = useBreakpoint();

  const settings = {
    dots: false,
    draggable: true,
    infinite: true,
    speed: 500,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="section-stack">
      <Heading title={title} subtitle={description} />
      {stacks.map((stack, index) => (
        <div className="list-stack" key={index}>
          <Row>
            <Col xs={24} sm={9} md={stack.grid || 4}>
              <div className="label">{stack.label}</div>
            </Col>
            <Col xs={24} sm={15} md={24 - stack.grid || 20}>
              <LazyLoad height={md ? 40 : 88} offset={500}>
                <Carousel
                  {...{
                    ...settings,
                    slidesToShow:
                      stack.items.length < 5 ? stack.items.length : 5,
                    className: classNames({
                      'container-small': stack.items.length < 5,
                    }),
                  }}
                >
                  {stack.items.map((logo, index) => (
                    <img src={logo.url} alt={logo.alt} key={index} />
                  ))}
                </Carousel>
              </LazyLoad>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};
