import { Title } from '@components/Title';
import classNames from 'classnames';
import { FC } from 'react';

type HeadingProps = {
  titleLevel?: 4 | 5 | 1 | 2 | 3;
  title: any;
  subtitle?: string;
  titleClassName?: string;
  subTitleClassName?: string;
};

export const Heading: FC<HeadingProps> = ({
  titleLevel,
  title,
  subtitle,
  titleClassName,
  subTitleClassName,
}) => {
  return (
    <div className="heading">
      <Title
        titleLevel={titleLevel}
        title={title}
        titleClassName={titleClassName}
      />
      {subtitle && (
        <div
          className={classNames('heading__subtitle', subTitleClassName)}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
    </div>
  );
};
