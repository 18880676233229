export const IconPlusBig = (props) => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.07064 16.5706C1.37477 16.5706 0 15.1959 0 13.5C0 11.8041 1.37477 10.4294 3.07064 10.4294H10.876V3.6529C10.876 1.9116 12.2876 0.5 14.0289 0.5C15.7702 0.5 17.1818 1.9116 17.1818 3.65289V10.4294H24.9294C26.6252 10.4294 28 11.8041 28 13.5C28 15.1959 26.6252 16.5706 24.9294 16.5706H17.1818V23.3471C17.1818 25.0884 15.7702 26.5 14.0289 26.5C12.2876 26.5 10.876 25.0884 10.876 23.3471V16.5706H3.07064Z"
        fill="#1155CC"
      />
    </svg>
  );
};
