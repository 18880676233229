import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { Heading } from '../Heading';

type PropsSectionAboutUs = {
  className?: string;
  knowHow: any;
  description?: string;
  title?: string;
};

export const SectionAboutUs = ({
  className,
  knowHow,
  description,
  title,
}: PropsSectionAboutUs) => {
  const { t } = useTranslation();
  const [blockActive, setBlockActive] = useState(0);

  const handleBlockActive = (index) => () => {
    setBlockActive(index);
  };

  return (
    <div
      className={classNames('section-about-us', {
        'on-demand-about-us': className,
      })}
    >
      <div className="container">
        <Heading
          title={title || t('product.txtWhatSetUs')}
          subtitle={description}
        />
      </div>
      <div className="about-us-list">
        {knowHow.map((item, index) => (
          <div
            className={classNames('about-us-block', {
              'block-active': index === blockActive,
            })}
            onMouseMove={handleBlockActive(index)}
            key={index}
          >
            <div className="about-us-content">
              <div className="block-top">
                <div className="block-title">{item.title}</div>
                <img
                  className="icon-arrow"
                  src="/icons/icon-right-arrow-outline.svg"
                  alt="Right Arrow"
                />
              </div>
              <div className="block-description">{item.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
