import React from 'react';
import { Col, Row, Typography } from 'antd';
import { IconDEX } from '@public/icons';

const { Title, Paragraph } = Typography;

export const SectionSummary = ({ title, descriptions = [] }) => {
  return (
    <div className="section-summary">
      <Row gutter={[48, 8]}>
        <Col xs={24} md={6}>
          <IconDEX />
          <Title level={2} className="section-summary__title">
            {title}
          </Title>
        </Col>
        <Col xs={24} md={18}>
          {descriptions.map((item, index) => (
            <Paragraph className="section-summary__description" key={index}>
              {item}
            </Paragraph>
          ))}
        </Col>
      </Row>
    </div>
  );
};
