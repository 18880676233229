import Link from 'next/link';
import { FC } from 'react';
import Image from 'next/image';
import { Grid } from 'antd';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

type CardDevelopmentProps = {
  title: string;
  description: string;
  link?: string;
  image?: string;
};

const { useBreakpoint } = Grid;

export const CardDevelopment: FC<CardDevelopmentProps> = ({
  title,
  description,
  link,
  image,
}) => {
  const { sm } = useBreakpoint();
  const lang = useTranslation();
  const isJapanese = lang.i18n.language === 'ja';

  const content = (
    <>
      <div className="image-wrapper">
        <Image
          src={image}
          alt={title}
          width={sm ? 68 : 54}
          height={sm ? 68 : 54}
          objectFit="contain"
          quality={100}
        />
      </div>

      <div className="card-development__meta">
        <h3 className="title">{title}</h3>
        <div
          className="description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
    </>
  );
  return link ? (
    <Link href={link}>
      <a
        className={classNames('card-development', {
          'japanese-layout': isJapanese,
        })}
      >
        {content}
      </a>
    </Link>
  ) : (
    <div
      className={classNames('card-development', {
        'japanese-layout': isJapanese,
      })}
    >
      {content}
    </div>
  );
};
