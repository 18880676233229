import { TFunction } from 'next-i18next';
import { getSiteUrl } from 'utils';

export const listLogo = (t: TFunction) => [
  [
    {
      image: '/images/about/ISO-27001-2013.webp',
      alt: 'Certified Company - ISO[27001:2013]',
      content: t('about-us.txtISO'),
      link: '',
    },
    {
      image: '/images/about/ISO-9001-20150.webp',
      alt: 'Certified Company - ISO[9001:2015]',
      content: t('about-us.iso_9001'),
      link: '',
    },
    {
      image: '/images/about/logo-top-blockchain.webp',
      alt: t('about-us.txtTopBlockChain'),
      content: t('home.txtGoodFirmLogo'),
      link: 'https://www.goodfirms.co/company/ekotek',
    },
    {
      image: '/images/about/top-mobile-app.webp',
      alt: t('about-us.txtTopMobile'),
      content: t('home.txtGoodFirmLogo'),
      link: 'https://www.goodfirms.co/company/ekotek',
    },

    {
      image: '/images/about/app-futa.webp',
      alt: t('about-us.txtB2b'),
      content: t('common.txtAppfutas'),
      link: '',
    },
    {
      image: '/images/about/goodfirm-award.webp',
      alt: 'Goodfirm awards',
      content: t('common.txtBestGood'),
      link: 'https://www.goodfirms.co/company/ekotek',
    },
  ],
  [
    {
      image: '/images/about/top-b2b-companies.webp',
      alt: t('about-us.txtB2b'),
      content: t('home.txtClutchLogo'),
      link: '',
    },
    {
      image: '/images/about/top-b2b-services.webp',
      alt: t('about-us.txtB2b'),
      content: t('home.txtClutchLogo'),
      link: '',
    },
    {
      image: '/images/about/logo-bronze.webp',
      alt: 'Token Vesting Portal Fintech Solution',
      content: t('home.txtStevieLogo'),
      link: '',
    },

    {
      image: '/images/about/logo-tech-review.webp',
      alt: 'Techreviewer',
      content: t('home.txtTechReviewLogo'),
      link: '',
    },
    {
      image: '/images/about/top_software_developers.webp',
      alt: 'top software developer',
      content: t('home.txtTechReviewLogo'),
      link: getSiteUrl('/ekoios-makes-the-list-of-top-software-development-companies-in-2023'),
    },
    // {
    //   image: '/images/about/logo-software.webp',
    //   alt: 'software developers vietnam',
    //   content: t('home.txtSofwareLogo'),
    //   link: '',
    // },
    // {
    //   image: '/images/about/logo-bga.webp',
    //   alt: t('about-us.txtJAB'),
    //   content: t('home.txtBGALogo'),
    //   link: '',
    // },
  ],
];

export const listLogoAboutPage = (t: TFunction) => [
  [
    {
      image: '/images/about/ISO-27001-2013.webp',
      alt: 'Certified Company - ISO[27001:2013]',
      content: t('about-us.txtISO'),
      link: '',
    },
    {
      image: '/images/about/ISO-9001-20150.webp',
      alt: 'Certified Company - ISO[9001:2015]',
      content: t('about-us.iso_9001'),
      link: '',
    },
    {
      image: '/images/about/top-b2b-companies.webp',
      alt: t('about-us.txtB2b'),
      content: t('home.txtClutchLogo'),
      link: '',
    },
    {
      image: '/images/about/app-futa.webp',
      alt: t('about-us.txtB2b'),
      content: t('common.txtAppfutas'),
      link: '',
    },
    {
      image: '/images/about/goodfirm-award.webp',
      alt: 'Goodfirm awards',
      content: t('common.txtBestGood'),
      link: 'https://www.goodfirms.co/company/ekotek',
    },
    {
      image: '/images/about/logo-bronze.webp',
      alt: 'Token Vesting Portal Fintech Solution',
      content: t('home.txtStevieLogo'),
      link: '',
    },
  ],
  [
    {
      image: '/images/about/logo-top-blockchain.webp',
      alt: t('about-us.txtTopBlockChain'),
      content: t('home.txtGoodFirmLogo'),
      link: 'https://www.goodfirms.co/company/ekotek',
    },
    {
      image: '/images/about/top-b2b-services.webp',
      alt: t('about-us.txtB2b'),
      content: t('home.txtClutchLogo'),
      link: '',
    },
    // {
    //   image: '/images/about/logo-bga.webp',
    //   alt: t('about-us.txtJAB'),
    //   content: t('home.txtBGALogo'),
    //   link: '',
    // },
    {
      image: '/images/about/logo-tech-review.webp',
      alt: 'Tech reviewer',
      content: t('home.txtTechReviewLogo'),
      link: '',
    },
    {
      image: '/images/about/software-outsourcing.webp',
      alt: 'Software Outsourcing',
      content: '',
      link: 'https://softwareoutsourcing.com/?utm_id=so_644a362f88129',
    },
    {
      image: '/images/about/top-mobile-app.webp',
      alt: t('about-us.txtTopMobile'),
      content: t('home.txtGoodFirmLogo'),
      link: 'https://www.goodfirms.co/company/ekotek',
    },
  ],
];

export const listLogoMobileAboutPage = (t: TFunction) => [
  {
    image: '/images/about/ISO-27001-2013.webp',
    alt: 'Certified Company - ISO[27001:2013]',
    content: t('about-us.txtISO'),
    link: '',
  },
  {
    image: '/images/about/ISO-9001-20150.webp',
    alt: 'Certified Company - ISO[9001:2015]',
    content: t('about-us.iso_9001'),
    link: '',
  },
  {
    image: '/images/about/top-b2b-companies.webp',
    alt: t('about-us.txtB2b'),
    content: t('home.txtClutchLogo'),
    link: '',
  },
  {
    image: '/images/about/app-futa.webp',
    alt: t('about-us.txtB2b'),
    content: t('common.txtAppfutas'),
    link: '',
  },
  {
    image: '/images/about/goodfirm-award.webp',
    alt: 'Goodfirm awards',
    content: t('common.txtBestGood'),
    link: 'https://www.goodfirms.co/company/ekotek',
  },
  {
    image: '/images/about/logo-bronze.webp',
    alt: 'Token Vesting Portal Fintech Solution',
    content: t('home.txtStevieLogo'),
    link: '',
  },
  {
    image: '/images/about/logo-top-blockchain.webp',
    alt: t('about-us.txtTopBlockChain'),
    content: t('home.txtGoodFirmLogo'),
    link: 'https://www.goodfirms.co/company/ekotek',
  },
  {
    image: '/images/about/top-b2b-services.webp',
    alt: t('about-us.txtB2b'),
    content: t('home.txtClutchLogo'),
    link: '',
  },
  // {
  //   image: '/images/about/logo-bga.webp',
  //   alt: t('about-us.txtJAB'),
  //   content: t('home.txtBGALogo'),
  //   link: '',
  // },
  {
    image: '/images/about/logo-tech-review.webp',
    alt: 'Techreviewer',
    content: t('home.txtTechReviewLogo'),
    link: '',
  },
  {
    image: '/images/about/software-outsourcing.webp',
    alt: 'Software Outsourcing',
    content: '',
    link: 'https://softwareoutsourcing.com/?utm_id=so_644a362f88129',
  },
  {
    image: '/images/about/top-mobile-app.webp',
    alt: t('about-us.txtTopMobile'),
    content: t('home.txtGoodFirmLogo'),
    link: 'https://www.goodfirms.co/company/ekotek',
  },
];

export const listLogoMobile = (t: TFunction) => [
  {
    image: '/images/about/ISO-27001-2013.webp',
    alt: 'Certified Company - ISO[27001:2013]',
    content: t('about-us.txtISO'),
    link: '',
  },
  {
    image: '/images/about/ISO-9001-20150.webp',
    alt: 'Certified Company - ISO[9001:2015]',
    content: t('about-us.iso_9001'),
    link: '',
  },
  {
    image: '/images/about/logo-top-blockchain.webp',
    alt: t('about-us.txtTopBlockChain'),
    content: t('home.txtGoodFirmLogo'),
    link: 'https://www.goodfirms.co/company/ekotek',
  },
  {
    image: '/images/about/top-mobile-app.webp',
    alt: t('about-us.txtTopMobile'),
    content: t('home.txtGoodFirmLogo'),
    link: 'https://www.goodfirms.co/company/ekotek',
  },
  {
    image: '/images/about/app-futa.webp',
    alt: t('about-us.txtB2b'),
    content: t('common.txtAppfutas'),
    link: '',
  },
  {
    image: '/images/about/goodfirm-award.webp',
    alt: 'Goodfirm awards',
    content: t('common.txtBestGood'),
    link: 'https://www.goodfirms.co/company/ekotek',
  },

  {
    image: '/images/about/top-b2b-companies.webp',
    alt: t('about-us.txtB2b'),
    content: t('home.txtClutchLogo'),
    link: '',
  },
  {
    image: '/images/about/top-b2b-services.webp',
    alt: t('about-us.txtB2b'),
    content: t('home.txtClutchLogo'),
    link: '',
  },
  {
    image: '/images/about/logo-bronze.webp',
    alt: 'Token Vesting Portal Fintech Solution',
    content: t('home.txtStevieLogo'),
    link: '',
  },

  {
    image: '/images/about/logo-tech-review.webp',
    alt: 'Techreviewer',
    content: t('home.txtTechReviewLogo'),
    link: '',
  },
  {
    image: '/images/about/top_software_developers.webp',
    alt: 'top software developer',
    content: t('home.txtTechReviewLogo'),
    link: getSiteUrl('/ekoios-makes-the-list-of-top-software-development-companies-in-2023'),
  },
  // {
  //   image: '/images/about/logo-software.webp',
  //   alt: 'software developers vietnam',
  //   content: t('home.txtSofwareLogo'),
  //   link: '',
  // },
  // {
  //   image: '/images/about/logo-bga.webp',
  //   alt: t('about-us.txtJAB'),
  //   content: t('home.txtBGALogo'),
  //   link: '',
  // },
];
