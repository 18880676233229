import classNames from 'classnames';

type PropsTitle = {
  titleLevel?: number;
  title: any;
  titleClassName?: string;
};

export const Title = ({
  titleLevel = 2,
  title,
  titleClassName,
}: PropsTitle) => {
  const HeadingTag = `h${titleLevel}` as keyof JSX.IntrinsicElements;

  return (
    <HeadingTag
      className={classNames('heading__title', titleClassName)}
      dangerouslySetInnerHTML={{ __html: title }}
    />
  );
};
